<template>
  <div class="text-center">
    <Loader :loading="loading" animation="calendar" />
    <h1 class="title">Planificador</h1>
    <div class="mx-12 my-6">
      <v-card>
        <v-row class="mr-10">
          <v-col cols="2" xl="2" lg="2" md="2" sm="12" xs="12" class="itemsCenter">
            <Date-Picker v-model="forms.search.fecha_inicio" label="Fecha inicio" offYear hideDetails offLimit />
          </v-col>
          <v-col cols="2" xl="2" lg="2" md="2" sm="12" xs="12" class="itemsCenter">
            <Date-Picker v-model="forms.search.fecha_fin" label="Fecha fin" offYear hideDetails offLimit />
          </v-col>
          <v-col cols="6" xl="5" lg="5" md="4" sm="12" xs="12" v-if="!userMode" class="itemsCenter">
            <v-autocomplete
              v-model="forms.search.ids_departamentos"
              :items="department"
              item-text="nombre"
              item-value="iddepartamento"
              label="Departamentos"
              prepend-icon="mdi-sitemap"
              persistent-hint
              no-data-text="No se encontraron resultados"
              :dense="forms.search.ids_departamentos.length < 4"
              :disabled="!forms.search.fecha_inicio && !forms.search.fecha_fin"
              chips
              deletable-chips
              multiple
              small-chips
              hide-details
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="4" xl="3" lg="5" md="4" sm="12" xs="12" v-if="userMode" class="itemsCenter">
            <v-autocomplete
              v-model="forms.search.ids_usuarios"
              :items="users"
              item-text="nombreCompleto"
              item-value="idusuario"
              label="Empleados"
              prepend-icon="mdi-account"
              persistent-hint
              no-data-text="No se encontraron resultados"
              :dense="forms.search.ids_usuarios.length < 4"
              :disabled="!forms.search.fecha_inicio && !forms.search.fecha_fin"
              chips
              deletable-chips
              multiple
              small-chips
              hide-details
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="1" xl="3" lg="3" md="4" sm="12" xs="12" class="itemsCenter">
            <Basic-Btn :text="userMode ? 'Departamentos' : 'Usuarios'" color="primary" :icon="userMode ? 'mdi-sitemap' : 'mdi-account'" @click="userMode = !userMode" :loading="loading" />
            <Basic-Btn text="Buscar" color="primary" icon="mdi-magnify" @click="getData()" :loading="loading" />
          </v-col>
        </v-row>
      </v-card>
      <br />
      <br />
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            {{ monthName }}
          </v-toolbar-title>
          <v-btn fab text small color="grey darken-2" @click="selectMonth -= 1">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="selectMonth += 1">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn outlined small color="grey darken-2" @click="nextPage()">
            <span>Planificar</span>
          </v-btn>
        </v-toolbar>
        <div v-if="avaibleUser.length == 0">
          <h2 class="primary--text">Información no disponible</h2>
          <lottie-animation ref="anim" :animationData="require('@/assets/animations/search.json')" :loop="true" :autoPlay="true" style="width: 100%; height: 400px" />
        </div>
        <v-simple-table fixed-header dense height="600px" v-if="avaibleUser.length > 0" :key="avaibleUser.length">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center sticky-column over-text border header-style">Empleado</th>
                <th class="header-style text-center inside-text border" v-for="(day, i) in days" :key="i">{{ day }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, n) in avaibleUser" :key="n">
                <td class="border sticky-column text-center">{{ item.nombreCompleto }}</td>
                <th class="border text-center action" v-for="(day, i) in days" :key="i" :style="'background-color:' + getColors(items[item.idusuario].eventos[day])">
                 <event-detail :data="items" :id="item.idusuario ? item.idusuario : 0" :day="item.idusuario ? day : ''">
                    <!-- {{ items[item.idusuario] ? items[item.idusuario].eventos[day][items[item.idusuario].eventos[day].length - 1].tipo : "" }} -->
                </event-detail>
                </th>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import services from "@/utils/services";
import moment from "moment";
import { Loader, BasicBtn, Calendar, DatePicker, EventDetail } from "@/components";
import _ from "lodash";
import LottieAnimation from "lottie-web-vue";

export default {
  name: "calendar",
  components: {
    Loader,
    BasicBtn,
    Calendar,
    DatePicker,
    EventDetail,
    LottieAnimation,
  },
  data: () => ({
    items: [],
    users: [],
    avaibleUser: [],
    loading: false,
    userMode: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    forms: {
      search: {
        ids_departamentos: [],
        ids_usuarios: [],
        fecha_inicio: "",
        fecha_fin: "",
      },
    },
    holidays: [],
    holidayNames: [],
    days: [],
    department: [],
    calendarValues: [
      { key: "date", value: "Fecha" },
      { key: "outTime", value: "Tiempo de retardo(horas)" },
    ],
    selectMonth: 0,
    monthName: "",
  }),
  watch: {
    selectMonth() {
      this.createTable();
    },
  },
  mounted() {
    this.getGepartament();
    this.forms.search.fecha_inicio = moment().startOf("month").format("YYYY-MM-DD");
    this.forms.search.fecha_fin = moment().endOf("month").format("YYYY-MM-DD");
    this.getUser();
  },
  methods: {
    async getUser() {
      this.loading = true;
      this.axios
        .get(services.routes.user)
        .then((response) => {
          this.users = response.data.data;
          for (let i = 0; i < this.users.length; i++) {
            if (this.users[i].fecha_borrado) {
              this.users.splice(i, 1);
              i--;
            }
          }
          for (let i = 0; i < this.users.length; i++) {
            this.users[i].nombreCompleto =
              this.users[i].detalles_usuarios.length > 0
                ? (this.users[i].detalles_usuarios[0].nombre ? " " + this.users[i].detalles_usuarios[0].nombre : "") + (this.users[i].detalles_usuarios[0].apellido_paterno ? " " + this.users[i].detalles_usuarios[0].apellido_paterno : "") + (this.users[i].detalles_usuarios[0].apellido_materno ? " " + this.users[i].detalles_usuarios[0].apellido_materno : "")
                : "No disponible";
          }
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getGepartament() {
      this.loading = true;
      this.axios
        .get(services.routes.department)
        .then((response) => {
          this.department = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    createTable() {
      this.monthName = this.upperString(moment(this.forms.search.fecha_inicio).add(this.selectMonth, "months").locale("es").startOf("month").format("MMMM YYYY"));
      let begin = moment(this.forms.search.fecha_inicio).add(this.selectMonth, "months" ).startOf("month").format("YYYY-MM-DD");
      let end = moment(this.forms.search.fecha_inicio).add(this.selectMonth, "months").endOf("month").format("YYYY-MM-DD");
      let total = moment(end).diff(moment(begin), "days") + 1;
      this.days = [];
      for (let i = 0; i < total; i++) {
        this.days.push(moment(begin).add(i, "days").format("YYYY-MM-DD"));
      }
    },
    getData() {
      this.loading = true;
      let data = {
        fecha_inicio: this.forms.search.fecha_inicio,
        fecha_fin: this.forms.search.fecha_fin,
      };
      if (this.userMode) {
        data.ids_usuarios = this.forms.search.ids_usuarios;
      } else {
        data.ids_departamentos = this.forms.search.ids_departamentos;
      }
      this.axios
        .post(services.routes.usersOrDeparment, { data })
        .then((response) => {
          this.items = response.data.data;
          this.setExtraInfo()
          this.alert.active = true;
          this.alert.color = "success";
          this.alert.msg = "Departamento creado exitosamente";
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
          this.userShow();
        });
    },
    upperString(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    nextPage() {
      this.$router.push("/pasos-planificador");
    },
    userShow() {
      this.avaibleUser = [];
      for (let user of this.users) {
        for (let key in this.items) {
          if (user.idusuario == key) {
            this.avaibleUser.push(user);
          }
        }
      }
      this.createTable();
    },
    getColors(array) {
      let color = 'white';
      if (array ? array.length > 0 : false) {
        if (array[array.length-1].tipo == "SALIDA" || array[array.length-1].tipo == "RETARDO") {
          color = 'green; color: white;';
        } else if (array[array.length-1].tipo == "FALTA" ) {
          color = 'red; color: white;';
        } else if (this.holidayNames.indexOf(array[array.length-1].tipo) >= 0) {
          color = 'orange; color: white;';
        } else if (array[array.length-1].tipo == "DOMINGO" ) {
          color = 'grey; color: white;';
        } else if (array[array.length-1].tipo == "POR ASISTIR" ) {
          color = 'white; color: black;';
        } else {
          color = 'rgb(24, 77, 3); color: white;';
        }
      }
      return color;
    },
    async setExtraInfo() {
      const holiday = await this.axios.get(services.routes.catalog + '/holiday?c=iddia_festivo&c=nombre&c=fecha_calendario')
      if (holiday.data.data) {
        for (let day of holiday.data.data) {
          this.holidays.push(day.fecha_calendario)
          this.holidayNames.push(day.nombre)
        }
      }
      for (let key in this.items) {
        for (let key2 in this.items[key].eventos) {
          if (!this.items[key].eventos[key2].length) {
            const fecha_ingreso = this.items[key].fecha_ingreso
            const day = moment(key2).day()
            const idx = this.holidays.indexOf(key2)
            if (idx >= 0) {
              this.items[key].eventos[key2].push({
                tipo: this.holidayNames[idx]
              })
            }
            else if (day == 0) {
              this.items[key].eventos[key2].push({
                tipo: 'DOMINGO'
              })
            }
            else if (fecha_ingreso && moment(key2).diff(moment(fecha_ingreso), 'days') >= 0) {
              this.items[key].eventos[key2].push({
                tipo: 'POR ASISTIR'
              })
            }
          }
        }
      }
    }
  },
};
</script>

<style scoped>
  .border {
    border-style: groove;
    border: 1px solid rgb(193, 188, 188);
    border-collapse: collapse;
  }
  .sticky-column {
    left: 0;
    top: 0;
    position: sticky;
    background-color: white;
  }
  .over-text {
    z-index: 2 !important;
  }
  .inside-text {
    z-index: 1 !important;
  }
  .header-style {
    vertical-align: bottom;
    height: 0px !important;
    background-color: brown !important;
    color: white !important;
  }
  .action:hover {
    background-color: rgb(66, 171, 40) !important;
    cursor: pointer !important;
  }
</style>
